
<template>
<div class="container user-login">
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-5">
      <div class="card border border-2">
          <div class="card-body p-4 px-md-5 py-lg-6 px-lg-6">
              <div class="">
                  <h3 class="text-primary font-weight-bold align-items-center">
                      <span class="">Login</span>
                  </h3>
                  <p class="mb-3 small" style="color:#999999">
                    Login your account to continue
                  </p>
              </div>
              <form class="mb-6">
                  <div class="form-group">
                      <input type="email" v-validate="'required|email'" v-model="data.email" name="email" 
                      :class="{ 'is-invalid': errors.has('email') }" class="form-control" placeholder="Your email">
                      <span class="text-danger invalid-feedback"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
                  </div>
                  <div class="form-group mb-5">
                      <input type="password" v-model="data.password" v-validate="'required|min:4|max:100'" name="password"
                       :class="{ 'is-invalid': errors.has('password') }" class="form-control" placeholder="Enter your password">
                      <span class="text-danger invalid-feedback"  v-show="errors.has('password')">{{ errors.first('password') }}</span>
                  </div>
                  <div class="mb-3 d-flex font-13 justify-content-between">
                      <div class="form-check">
                          <input v-model="data.remember" type="checkbox" id="remember" class="form-check-input">
                          <label for="remember" class="form-check-label fs-base">Remember me</label>
                      </div>
                      <a :href="absoluteUrl('/forgot-password')" class="btn-link text-success">Forgot password?</a>
                  </div>
                  <button @click.prevent="submitData()" class="btn btn-block btn-success"  type="button">
                    Sign In
                  </button>
              </form>
              <p class="mt-5 mb-0 text-center" style="color:#999999">
                Don't have an account yet? <router-link to="/register" class="text-success font-weight-bold" >Signup</router-link>.
              </p>
          </div>
      </div>
    </div>
  </div>
</div>

<!-- end row -->
</template>

<script>

export default {
  data() {
    return {
      data: {
        email: '',
        password: '',
        role: 'worker',
        remember: true
      },
      passwordType: "password",
    }
  },
  methods: {
    passwordVisibility(){
      this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(this.absoluteUrl('/api/login'), formData)
        .then((response)=> {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.resetForm()
            this.$store.commit("SET_AUTH_TOKEN", response.data.data)
            location.replace(this.$route.query.intended || '/')
          }
        })
      });
    },
    resetForm(){
      this.data = {
        email: '',
        password: '',
        role: 'worker',
        remember: true
      }
      this.$validator.reset()
    },
   },
  }
</script>
